import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import OutRoutes from "./OutRoutes";

import Loader from "../components/Loader";
import NoDataFound from "../screen/NoDataFound";
// import ChauffeursFormCover from "../screen/home/ChauffeursFormCover";
// import AffiliateForm from "../screen/home/AffiliateForm";
const ReservationComp = lazy(() => import('../screen/home/ReservationComp'));
const Home = lazy(() => import('../screen/home/Home'));
const ContactUs = lazy(() => import('../screen/ContactUs'));
const Privacy = lazy(() => import('../screen/Privacy'));
const Terms = lazy(() => import('../screen/Terms'));
const ChauffeursFormCover = lazy(() => import('../screen/home/ChauffeursFormCover'));
const AffiliateForm = lazy(() => import('../screen/home/AffiliateForm'));
const OperationForm = lazy(() => import('../screen/home/OperationForm'));
const DeleteAccount = lazy(() => import('../screen/DeleteAccount'));

const Index = () => {
    return (
        <>
            <Suspense fallback={<Loader />}>
                <BrowserRouter>
                    <Routes>
                        <Route element={<OutRoutes />}>
                            <Route path="/" element={<ReservationComp />} />
                            <Route path="/operation" element={<Home />} />
                            <Route path="/contact_us" element={<ContactUs />} />
                            <Route path="/privacy&policy" element={<Privacy />} />
                            <Route path="/terms&conditions" element={<Terms />} />
                            <Route path="/delete-account" element={<DeleteAccount />} />
                        </Route>
                        <Route path="/operation-form" element={<OperationForm />} />
                        <Route path="/chauffeurs" element={<ChauffeursFormCover />} />
                        <Route path="/affiliate-network" element={<AffiliateForm />} />
                        <Route path="/quick-reservation" element={<ReservationComp />} />
                        <Route path="*" element={<NoDataFound />} />
                    </Routes>
                    <ToastContainer autoClose={1000} />
                </BrowserRouter>
            </Suspense>
        </>
    )
}
export default Index